<template>
  <div-wrapper className="success-cnt">
    <section class="mid-cnt">
      <div-wrapper className="info-box">
        <div-wrapper
          className="img-box"
          v-if="$route.query.status === 'success'"
        >
          <successful-icon />
        </div-wrapper>
        <h1 v-if="$route.query.status === 'success'">
          {{ $t('_congratulation') }}
        </h1>
        <p>
          {{ message }}
        </p>
      </div-wrapper>
      <span
        @click="clearHistory"
        class="home-btn"
        v-if="$route.query.status === 'success'"
      >Go to Home</span>
      <!-- <help-videos :videos="videos" /> -->
    </section>
  </div-wrapper>
</template>
<script>
// import { HELP_VIDEOS } from '@/constant/HelpVideoConst';
import { getMessageByType } from '../utils/Helper';
import DivWrapper from '@/components/form/DivWrapper';
import SuccessfulIcon from '@/components/svgIcons/SuccessfulIcon';
// import HelpVideos from '@/components/HelpVideos';
export default {
  components: {
    SuccessfulIcon,
    DivWrapper,
    // HelpVideos,
  },

  data() {
    return {
    //   videos: HELP_VIDEOS,
      message: '',
    };
  },

  created() {
    this.$swal({
      text: getMessageByType(this.$route.query.type, this),
      icon: 'success',
    });
  },

  methods: {
    clearHistory(){
        history.go(-(history.length - 1));
        this.$router.push('/')
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_successfully.scss';
</style>
